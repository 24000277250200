import type { FC, MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import {
  AccessTime as AccessTimeIcon,
  Block as BlockIcon,
  Cached as CachedIcon,
  CheckCircle as CheckCircleIcon,
  CloudOff as CloudOfIcon,
  Create as CreateIcon,
  DeleteOutline as DeleteOutlineIcon,
  PublishOutlined as PublishOutlinedIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import type { BoxProps as MuiBoxProps } from '@mui/material';
import { Box, Grid, IconButton, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { differenceInMinutes, format, parseISO } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'src/contexts/ConfirmContext';
import { TestIds } from 'src/testIds';
import { Appointment, AppointmentStatus, RecurrenceType } from 'src/types';
import { routes } from 'src/services/routing';
import {
  actions as appointmentRecordsActions,
  getters as appointmentRecordsGetters,
} from 'src/services/state/AppointmentRecords';
import { getters as appointmentsGetters } from 'src/services/state/Appointments';
import { getters as customersGetters } from 'src/services/state/Customers';
import type { Theme } from 'src/theme/types';
import { CardWrapper } from 'src/components/CardWrapper/CardWrapper';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';

import useStyles from './AppointmentCard.styles';

const ComponentTestIds = TestIds.components.appointmentCard;

export interface AppointmentCardProps extends MuiBoxProps {
  appointment: Appointment;
  showAppointmentDetails?: boolean;
  compact?: boolean;
}

export const AppointmentCard: FC<AppointmentCardProps> = ({
  appointment,
  showAppointmentDetails,
  compact: isCompact = false,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<any>();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const customer = customersGetters.getCustomerById(appointment.customerId);
  const status = appointmentsGetters.getExtendedAppointmentStatus(appointment);
  const isRecurring = appointment.recurrenceType > RecurrenceType.ONCE;
  const isReadOnly =
    status !== AppointmentStatus.UPLOAD_PENDING &&
    status === AppointmentStatus.HAS_RECORD;
  const allowsRecordCreation =
    status === AppointmentStatus.HAS_CLEARANCE ||
    status === AppointmentStatus.IS_DELAYED ||
    status === AppointmentStatus.UPLOAD_PENDING;

  const endTimeDate = parseISO(`${appointment.dateDay}T${appointment.endTime}`);
  const startTimeDate = parseISO(
    `${appointment.dateDay}T${appointment.startTime}`,
  );
  const durationInMinutes = differenceInMinutes(endTimeDate, startTimeDate);
  const showStatusDescription =
    durationInMinutes > 45 && !showAppointmentDetails;

  const appointmentRecordPending =
    appointmentRecordsGetters.getAppointmentRecordPending(
      appointment.id,
      appointment.recurrenceIndex,
    );

  let actionIcon;
  let statusClass;
  let statusIcon;
  let statusText;
  let testStatus;

  switch (status) {
    case AppointmentStatus.HAS_CLEARANCE:
      actionIcon = <CreateIcon fontSize="small" />;
      statusClass = classes.hasClearance;
      statusIcon = undefined;
      statusText = t('AppointmentCard.StatusText.hasClearance', {
        duration: durationInMinutes / 60,
      });
      testStatus = ComponentTestIds.hasClearance;

      break;

    case AppointmentStatus.HAS_RECORD:
      actionIcon = undefined;
      statusClass = classes.hasRecord;
      statusIcon = (
        <CheckCircleIcon className={classes.statusIcon} fontSize="small" />
      );
      statusText = t('AppointmentCard.StatusText.hasRecord');
      testStatus = ComponentTestIds.hasRecord;

      break;

    case AppointmentStatus.IS_DELAYED:
      actionIcon = <CreateIcon fontSize="small" />;
      statusClass = classes.isDelayed;
      statusIcon = (
        <WarningIcon className={classes.statusIcon} fontSize="small" />
      );
      statusText = t('AppointmentCard.StatusText.delayed');
      testStatus = ComponentTestIds.delayed;

      break;

    case AppointmentStatus.UPLOAD_PENDING:
      actionIcon = <PublishOutlinedIcon fontSize="small" />;
      statusClass = classes.uploadPending;
      statusIcon = (
        <CloudOfIcon className={classes.statusIcon} fontSize="small" />
      );
      statusText = t('AppointmentCard.StatusText.uploadPending');
      testStatus = ComponentTestIds.uploadPending;

      break;

    default:
      actionIcon = <CreateIcon fontSize="small" />;
      statusClass = classes.default;
      statusIcon = (
        <BlockIcon className={classes.statusIcon} fontSize="small" />
      );
      statusText = t('AppointmentCard.StatusText.default');
      testStatus = undefined;

      break;
  }

  /**
   * Handles redirecting to the AppointmentRecordForm when the card was clicked.
   */
  const handleClickBox = () => {
    if (!allowsRecordCreation) return;
    history.push(
      generatePath(routes.stack.routes.appointmentRecord.path, {
        customerId: appointment.customerId,
        id: appointment.id,
        index:
          appointment.recurrenceIndex === null
            ? undefined
            : appointment.recurrenceIndex,
      }),
      { stackRoot: location },
    );
  };

  /**
   * Handles differentiating between recurring and single appointments when
   * generating the route and redirecting to the view.
   */
  const goToEditView = (editRecurrence = false) => {
    history.push(
      generatePath(routes.stack.routes.appointmentEdit.path, {
        id: appointment.id,
        index:
          appointment.recurrenceIndex === null
            ? undefined
            : appointment.recurrenceIndex,
        singleOrEvery: editRecurrence ? 'every' : 'single',
      }),
      { stackRoot: location },
    );
  };

  /**
   * Upload the appointment's pending record.
   */
  const uploadRecordPending = async () => {
    if (!appointmentRecordPending) return;

    try {
      await confirm({
        description: t('AppointmentRecordForm.submitDescription'),
        primaryText: t('AppointmentRecordForm.submit'),
        title: t('AppointmentRecordForm.submitTitle'),
      });

      setIsLoading(true);

      // Parse the pending appointment record observable to usable post data.
      const data = toJS(appointmentRecordPending);

      await appointmentRecordsActions.createAppointmentRecord({ data });

      enqueueSnackbar(t('AppointmentRecordForm.successMessage'), {
        variant: 'success',
      });
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response?.data) {
        const errorMessage =
          error.response.data.detail ||
          error.response.data.nonFieldErrors?.join(' - ');

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('General.somethingWentWrong'), {
          variant: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Delete the appointment's pending record.
   */
  const deleteRecord = async (event: MouseEvent) => {
    event.stopPropagation();

    if (!appointmentRecordPending) return;

    try {
      await confirm({
        description: t('AppointmentRecordForm.submitDeleteDescription'),
        primaryText: t('AppointmentRecordForm.submitDelete'),
        title: t('AppointmentRecordForm.submitDeleteTitle'),
      });

      // Parse the pending appointment record observable to usable post data.
      const data = toJS(appointmentRecordPending);

      appointmentRecordsActions.removeAppointmentRecordPending(data);

      enqueueSnackbar(t('AppointmentCard.deleteSuccess'), {
        variant: 'success',
      });
    } catch (error: any) {
      if (error === 'onClose' || error === 'onSecondary') return;

      if (error.response) {
        const errorMessage =
          error.response.data.detail ||
          error.response.data.nonFieldErrors.join(' - ');

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('General.somethingWentWrong'), {
          variant: 'error',
        });
      }
    }
  };

  /**
   * Handles the card's action icon depending on the appointment's state.
   */
  const handleActionClick = async (event: MouseEvent) => {
    event.stopPropagation();

    if (isReadOnly) return;

    if (status === AppointmentStatus.UPLOAD_PENDING) {
      return uploadRecordPending();
    }

    if (!isRecurring) {
      return goToEditView();
    }

    if (isRecurring) {
      return await confirm({
        description: t('AppointmentCard.dialogDescription', {
          date: format(new Date(appointment.dateDay), 'dd.MM.yyyy'),
        }),
        onPrimary: { shouldResolve: true, valueReason: false },
        onSecondary: { shouldResolve: true, valueReason: true },
        primaryText: t('AppointmentCard.appointment'),
        secondaryText: t('AppointmentCard.recurringAppointment'),
        title: t('AppointmentCard.dialogTitle'),
      })
        .then((editRecurrence) => goToEditView(editRecurrence as boolean))
        .catch(() => null);
    }
  };

  return (
    <CardWrapper
      className={clsx(classes.pointer, statusClass, className)}
      data-test-appointment-id={appointment.id}
      data-test-id={ComponentTestIds.card}
      data-test-status={testStatus}
      onClick={handleClickBox}
      {...props}
    >
      {isLoading && (
        <LoadingScreen
          className={classes.loadingSpinner}
          onClick={(event) => event.stopPropagation()}
          size={25}
        />
      )}

      <Grid container>
        <Grid container item xs={12} alignItems="center">
          {isRecurring && !isCompact && (
            <CachedIcon className={classes.recurrenceIcon} />
          )}
          {isCompact && (
            <>
              <AccessTimeIcon className={classes.accessTimeIcon} />
              <Typography className={classes.timeText} variant="h6">
                {appointment.startTime.substring(0, 5)}
              </Typography>
            </>
          )}

          <Typography variant="h6">
            {customer?.firstName} {customer?.lastName}
          </Typography>

          {actionIcon && !isCompact && (
            <IconButton
              data-test-id={ComponentTestIds.actionIcon}
              onClick={handleActionClick}
              size="small"
              style={{ marginLeft: 'auto' }}
            >
              {actionIcon}
            </IconButton>
          )}
        </Grid>

        {(showStatusDescription || showAppointmentDetails) && (
          <Grid container wrap="nowrap" item xs={12}>
            {statusIcon && !isCompact && statusIcon}

            {showAppointmentDetails && (
              <Box flexGrow={1}>
                <Typography
                  style={{ ...theme.typography.italic }}
                  variant="body1"
                >
                  {t('AppointmentCard.StatusText.date', {
                    date: format(new Date(appointment.dateDay), 'dd.MM.yyyy'),
                  })}
                </Typography>

                <Typography
                  component="span"
                  style={{ ...theme.typography.italic }}
                  variant="body1"
                >
                  {t('AppointmentInformation.start', {
                    startTimeFormatted: appointment.startTime.substring(0, 5),
                  })}
                </Typography>

                <Typography
                  component="span"
                  style={{
                    ...theme.typography.italic,
                    marginLeft: theme.spacing(3),
                  }}
                  variant="body1"
                >
                  {t('AppointmentCard.StatusText.duration', {
                    duration: durationInMinutes / 60,
                  })}
                </Typography>
              </Box>
            )}

            {showStatusDescription && !isCompact && (
              <Box flexGrow={1}>
                <Typography variant="body1">{statusText}</Typography>
              </Box>
            )}

            {status === AppointmentStatus.UPLOAD_PENDING && (
              <IconButton
                data-test-id={ComponentTestIds.deleteIcon}
                onClick={deleteRecord}
                size="small"
                style={{ alignSelf: 'flex-end' }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    </CardWrapper>
  );
};

export default observer(AppointmentCard);
