import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { Theme } from 'src/theme/types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    accessTimeIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(0.5),
    },
    default: {
      background: theme.palette.background.dark,
    },
    hasClearance: {
      background: theme.palette.ternary.main,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    hasRecord: {
      background: theme.palette.success.light,
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.dark,
      cursor: 'auto',
    },
    isDelayed: {
      background: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    pointer: {
      cursor: 'pointer',
    },
    uploadPending: {
      background: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
    },
    recurrenceIcon: {
      fontSize: 'inherit',
      marginRight: theme.spacing(0.5),
    },
    statusIcon: {
      width: 16,
      marginRight: theme.spacing(0.5),
    },
    timeText: {
      marginRight: theme.spacing(0.5),
    },
    loadingSpinner: {
      backgroundColor: alpha(theme.palette.background.default, 0.5),
      width: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      zIndex: 1,
    },
  }),
  { name: 'AppointmentCard' },
);

export default useStyles;
